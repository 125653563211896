<template>
  <div class="icp_container" @click="goICP">
    <p>
      Copyright © 2021-{{ timeNow }} 顺佳三位一体 {{ hostName }} All Rights
      Reserved.
    </p>
    <p>备案号：{{ beiAnNo }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goICP() {
      location.href = "https://beian.miit.gov.cn";
    },
  },
  computed: {
    hostName() {
      return window.location.hostname;
    },
    timeNow() {
      return new Date().getFullYear();
    },
    beiAnNo() {
      // debugger;
      if (window.location.hostname.includes("isungoodedu.com")) {
        return "浙ICP备2022001479号-1";
      } else if (window.location.hostname.includes("isungoodedu.cn")) {
        return "浙ICP备2023039813号-1";
      } else if (window.location.hostname.includes("isunplus.cn")) {
        return "浙ICP备2024113866号-1";
      } else if (window.location.hostname.includes("120.55.47.49")) {
        return "原始IP-无展示";
      } else {
        return "测试备案号";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icp_container {
  text-align: center;
  margin: 20px 0 40px;
  cursor: pointer;
  p {
    /* font-size: 12px; */
    color: #999;
    margin: 0 auto;
    width: 100%;
  }
}
</style>
