<template>
  <div class="wrap">
    <img
      v-for="(item, index) in 9"
      :key="index"
      :src="require('@/assets/images/home/' + (index + 1) + '.png')"
    />
    <ICP />
  </div>
</template>

<script>
import ICP from "@/components/ICP.vue";
export default {
  name: "Home",
  components: { ICP },
};
</script>

<style lang="scss" scoped>
.wrap {
  img {
    width: 100%;
  }
}
</style>
